import { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three-stdlib";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";

export const Sun = () => {
  const sunRef = useRef<THREE.Mesh>(null);
  const { scene } = useLoader(GLTFLoader, "planets/sun.glb"); // Update this path

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (sunRef.current) {
      sunRef.current.rotation.y = elapsedTime / 25.38;
    }
  });

  return (
    <mesh ref={sunRef} position={[0, 0, 0]}>
      <primitive
        ref={sunRef}
        object={scene}
        scale={new THREE.Vector3(0.05, 0.05, 0.05)}
      />
      <OrbitControls />
    </mesh>
  );
};
