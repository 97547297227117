import "./styles.scss";

const CosmosLoader = (): JSX.Element => {
  return (
    <div className="cosmos-loader">
      <div className="star">
        Loading...
      </div>
      <div className="mercury orbit">
        <div className="planet"></div>
      </div>
      <div className="venus orbit">
        <div className="planet"></div>
      </div>
      <div className="earth orbit">
        <div className="planet orbit"></div>
      </div>
      <div className="mars orbit">
        <div className="planet"></div>
      </div>
      <div className="jupiter orbit">
        <div className="planet"></div>
      </div>
      <div className="saturn orbit">
        <div className="planet"></div>
      </div>
      <div className="uranus orbit">
        <div className="planet"></div>
      </div>
      <div className="neptune orbit">
        <div className="planet"></div>
      </div>
    </div>
  );
};

export default CosmosLoader;
