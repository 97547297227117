import { useEffect, useRef } from "react";
import "./styles.scss";

const Alien = (): JSX.Element => {
    const ufoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
          const eyes = ufoRef.current?.querySelector('.eyes') as HTMLElement; // Narrow down the type
          if (!eyes) return; // Ensure eyes element exists before proceeding
          const mouseX = e.clientX; // Get mouseX relative to body
          const mouseY = e.clientY; // Get mouseY relative to body
          const radianDegrees = Math.atan2(mouseX - eyes.getBoundingClientRect().left, mouseY - eyes.getBoundingClientRect().top);
          const rotationDegrees = (radianDegrees * (180 / Math.PI) * -1) + 180;
          eyes.style.transform = `rotate(${rotationDegrees}deg)`; // Access style property
        };
    
        // Add event listener on mount
        document.body.addEventListener('mousemove', handleMouseMove);
    
        // Remove event listener on unmount
        return () => {
          document.body.removeEventListener('mousemove', handleMouseMove);
        };
      }, []);
  
    return (
      <div className="ufo" ref={ufoRef}>
        <div className="monster" style={{ color: 'rgb(115 169 184)' }}>
          <div className="body">
            <div className="ear"></div>
            <div className="ear"></div>
            <div className="vampi-mouth">
              <div className="vampi-tooth"></div>
            </div>
          </div>
          <div className="eye-lid">
            <div className="eyes">
              <div className="eye"></div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Alien;
