import "./styles.scss";

const Gains = (): JSX.Element => {
  return (
    <div className="gains">
      <div className="project_text">
        <div className="title">GAINS Associates</div>
        <div className="project_description">
          GAINS Associates is the #1 Crowdsourcing Platform Accessible for the
          masses. The token is designed to offer a structural process for
          individuals interested in promising new projects.&nbsp;
          <a
            href="https://app.gains-associates.com/"
            target="_blank"
            rel="noreferrer"
          >
            View Project{" "}
          </a>
        </div>
      </div>
      <img src="images/gains.png" className="gains_image" alt="" />
    </div>
  );
};

export default Gains;
