import "./styles.scss";

const Yeti = (): JSX.Element => {
  return (
    <div className="yeti">
      <img src="images/yeti.png" className="yeti_image" alt="" />
      <div className="project_text">
        <div className="title">Yeti Town</div>
        <div className="project_description">
          Yeti Town is an innovative NFT project featuring 4,444 Genesis Yetis
          as membership tokens. These NFTs offer holders exclusive access to
          unique collectibles, a vibrant community, branded merchandise, and
          future metaverse developments.&nbsp;
          <a href="https://yetitown.com/" target="_blank" rel="noreferrer">
            View Project{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Yeti;
