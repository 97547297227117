import { useEffect, useRef, useState } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three-stdlib";
import { useAnimations } from "@react-three/drei";
import * as THREE from "three";

const EarthAnimal = () => {
  const animalRef = useRef<THREE.Mesh>(null);
  const movementRef = useRef({
    directionX: Math.random() < 0.5 ? -1 : 1,
    directionZ: Math.random() < 0.5 ? -1 : 1,
    speed: 0.003, // Speed of movement
  });
  const { scene, animations } = useLoader(GLTFLoader, "velociraptor.glb"); // Note we now extract mixer
  const { size } = useThree();
  const { actions, mixer } = useAnimations(animations, animalRef);
  const [isRunning, setIsRunning] = useState(true);

  useFrame(() => {
    if (animalRef.current && isRunning) {
      // Movement and rotation logic
      animalRef.current.position.x +=
        movementRef.current.directionX * movementRef.current.speed;
      animalRef.current.position.z +=
        movementRef.current.directionZ * movementRef.current.speed;

      // Boundary checks
      if (
        animalRef.current.position.x > size.width / 700 ||
        animalRef.current.position.x < -size.width / 700
      ) {
        movementRef.current.directionX *= -1;
      }
      const depth = -15; // Assuming a logical depth boundary for your scene
      if (
        animalRef.current.position.z > depth ||
        animalRef.current.position.z < -depth
      ) {
        movementRef.current.directionZ *= 1;
      }

      // Rotation based on movement
      const angle = Math.atan2(
        movementRef.current.directionX,
        movementRef.current.directionZ
      );
      animalRef.current.rotation.y = angle;
    }
  });

  useEffect(() => {
    if (actions.walk1 && isRunning) {
      actions.walk1.reset().play();
    }
  }, [actions, isRunning]);

  const handleClick = () => {
    if (actions.walk1) {
      actions.walk1.reset().stop();
    }
    if (actions.eat) {
      actions.eat.reset().play();
      actions.eat.setLoop(THREE.LoopOnce, 1);
      mixer.addEventListener("finished", () => {
        setIsRunning(true); // Resume running after idle animation
      });
    }
    setIsRunning(false);
  };

  return (
    <mesh ref={animalRef} position={[0, 9, 0]} onClick={handleClick}>
      <primitive
        object={scene}
        scale={new THREE.Vector3(10, 10, 10)} // Scale the model down
        onPointerOver={() => (document.body.style.cursor = "pointer")}
        onPointerOut={() => (document.body.style.cursor = "auto")}
      />
    </mesh>
  );
};

export default EarthAnimal;
