import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import "./index.scss";
import { Sun } from "../../components/models/sun";
import Planets from "../../components/models/planets";
import { PlanetPositions, planets } from "./types";
import CosmosLoader from "../../components/loader";
import Menu from "../../components/menu";

const Controls = () => {
  return (
    <OrbitControls
      maxDistance={300}
      enablePan={true}
      minPolarAngle={Math.PI / 4}
      maxPolarAngle={Math.PI / 2}
    />
  );
};

function calculateCoordinates(
  x0: number,
  z0: number,
  r: number,
  T: number,
  t: number
) {
  const theta0 = Math.atan2(z0, x0);

  const omega = (2 * Math.PI) / T;

  const theta = theta0 + omega * t;

  const x = r * Math.cos(theta);
  const z = r * Math.sin(theta);

  return { x: x, z: z };
}

const HomePage: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [planetPositions, setPlanetPositions] = useState<PlanetPositions>({
    mercury: [28.5, 0, 0],
    venus: [29.5, 0, 0],
    earth: [30.5, 0, 0],
    mars: [31.524, 0, 0],
    jupiter: [35.2, 0, 0],
    saturn: [44.58, 0, 0],
    uranus: [52.22, 0, 0],
    neptune: [56.05, 0, 0],
    lastUpdate: new Date(),
  });

  useEffect(() => {
    const earthYearInSeconds = 60;
    const scale = earthYearInSeconds / 365.25;

    const orbital = {
      mercury: { period: 1000 * scale, radius: 28.5 },
      venus: { period: 1250 * scale, radius: 29.5 },
      earth: { period: 1550 * scale, radius: 30.5 },
      mars: { period: 2000 * scale, radius: 31.524 },
      jupiter: { period: 4333 * scale, radius: 35.2 },
      saturn: { period: 10759 * scale, radius: 44.58 },
      uranus: { period: 30685 * scale, radius: 52.22 },
      neptune: { period: 60190 * scale, radius: 56.05 },
    };

    const updatePositions = () => {
      setPlanetPositions((prevPositions: any) => {
        const newPositions = { ...prevPositions };
        const currentTime = Date.now();
        const deltaTime = (currentTime - prevPositions.lastUpdate) / 1000;

        Object.entries(orbital).forEach(([planet, period]) => {
          const radius = prevPositions[planet as keyof PlanetPositions][0];
          const currentAngle =
            prevPositions[planet as keyof PlanetPositions][2];
          const a = calculateCoordinates(
            radius,
            currentAngle,
            period.radius,
            period.period,
            deltaTime
          );

          newPositions[planet as keyof PlanetPositions] = [a.x, 0, a.z];
        });

        newPositions.lastUpdate = currentTime;
        return newPositions;
      });
    };

    const interval = setInterval(updatePositions, 1000 / 60);
    return () => clearInterval(interval);
  }, []);

  const stopLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    const handleMouseMove = () => {
      if (audioRef.current) {
        audioRef.current.play().catch(() => console.log());
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="homepage">
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#222",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {<CosmosLoader />}
        </div>
      )}
      {!loading && (
        <audio ref={audioRef} loop autoPlay>
          <source src="sounds/background-music.mp3" />
        </audio>
      )}
      <Canvas style={{ height: "100vh", width: "100vw" }}>
        <ambientLight intensity={1} />
        <Stars count={10000} saturation={1000} fade />
        <mesh position={[-30, 0, 0]}>
          <Suspense fallback={null}>
            <Sun />
            {planets.map((e) => {
              return (
                <Planets
                  key={e.name}
                  name={e.name}
                  scale={e.scale}
                  scane={e.scane}
                  rotation={e.rotation}
                  position={planetPositions[e.name]}
                  stopLoading={stopLoading}
                  path={e.link}
                />
              );
            })}
          </Suspense>
        </mesh>
        <Controls />
      </Canvas>
      {!loading && <Menu />}
    </div>
  );
};

export default HomePage;
