import "./styles.scss";

const PassXYZ = (): JSX.Element => {
  return (
    <div className="passxyz">
      <div className="project_text">
        <div className="title">PassXYZ</div>
        <div className="project_description">
          Pass.XYZ is an innovative platform that blends a community-governed
          NFT marketplace with a social media framework. It emphasizes community
          engagement by allowing users to curate and govern the content,
          fostering a unique ecosystem where creators, collectors, and
          enthusiasts can interact.
        </div>
      </div>
      <img src="images/passXYZ.png" className="passxyz_image" alt="" />
    </div>
  );
};

export default PassXYZ;
