import { useEffect, useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three-stdlib";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import { useNavigate } from "react-router";

const Planets = ({
  position,
  scane,
  scale,
  rotation,
  name,
  stopLoading,
  path,
}: {
  position: any;
  scane: string;
  scale: number;
  rotation: number;
  name: string;
  path: string;
  stopLoading: () => void;
}) => {
  const planetRef = useRef<THREE.Mesh>(null);
  const { scene } = useLoader(GLTFLoader, scane);
  const navigate = useNavigate();

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (planetRef.current) {
      planetRef.current.rotation.y = elapsedTime / rotation;
    }
  });

  useEffect(() => {
    if (planetRef?.current?.name) {
      stopLoading();
    }
  }, [planetRef, stopLoading]);

  const planetClick = (): void => {
    document.body.style.cursor = "auto";
    navigate(path);
  };

  return (
    <mesh ref={planetRef} position={position} name={name} onClick={planetClick}>
      <primitive
        object={scene}
        scale={new THREE.Vector3(scale, scale, scale)}
        onPointerOver={() => (document.body.style.cursor = "pointer")}
        onPointerOut={() => (document.body.style.cursor = "auto")}
      />
      <OrbitControls />
    </mesh>
  );
};

export default Planets;
