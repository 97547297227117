import { useLocation, useNavigate } from "react-router";
import { pages } from "../../utils/constants";
import "./styles.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useState } from "react";

const Header = ({
  background,
  mobile_menu_bg,
}: {
  background: string;
  mobile_menu_bg?: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const onNavigate = (path: string): void => {
    navigate(path);
  };

  return (
    <>
      <div className="header" style={{ background: background }}>
        <div onClick={() => onNavigate("/")} className="page">
          Home
        </div>
        <div className="pages">
          {width > 900 ? (
            pages.map((e) => {
              if (location.pathname !== e.link && !!e.link) {
                return (
                  <div
                    onClick={() => onNavigate(e.link)}
                    className="page"
                    key={e.name}
                  >
                    {e.name}
                  </div>
                );
              }
              return "";
            })
          ) : (
            <img
              src="icons/menu.svg"
              alt=""
              width={30}
              height={30}
              onClick={() => setIsOpen(true)}
            />
          )}
          {width > 900 && (
            <>
              <a
                href="https://www.linkedin.com/in/narek-a-64a769224/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="icons/linkedin.svg" alt="" width={20} height={20} />
              </a>
              <a
                href="https://github.com/Narek-Avdalyan"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="icons/github_light.svg"
                  alt=""
                  width={23}
                  height={23}
                />
              </a>
              <a
                href="https://t.me/Nar_Yan"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="icons/telegram.svg"
                  alt=""
                  width={25}
                  height={25}
                />
              </a>
            </>
          )}
        </div>
      </div>
      {width < 900 && isOpen && (
        <div className="mobile_menu">
          <div className="menu_bg" onClick={() => setIsOpen(false)} />
          <div
            className="menu_content"
            style={{ background: mobile_menu_bg }}
            onClick={(e) => e.preventDefault()}
          >
            <div className="close_icon">
              <img
                src="icons/close.svg"
                alt=""
                onClick={() => setIsOpen(false)}
                width={30}
                height={30}
              />
            </div>
            <div className="pages">
              {pages.map((e) => {
                if (location.pathname !== e.link && !!e.link) {
                  return (
                    <div
                      onClick={() => onNavigate(e.link)}
                      className="mobile_page"
                      key={e.name}
                    >
                      <img src={e.image} alt="" width={25} height={25} />
                      {e.name}
                    </div>
                  );
                }
                return "";
              })}
            </div>
            <div className="social_links">
              <div>
                <a
                  href="https://www.linkedin.com/in/narek-a-64a769224/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="icons/linkedin.svg" alt="" width={20} height={20} />
                </a>
                <div>
                  Linkedin
                </div>
              </div>
              <div>
                <a
                  href="https://github.com/Narek-Avdalyan"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="icons/github_light.svg"
                    alt=""
                    width={23}
                    height={23}
                  />
                </a>
                <div>
                  GitHub
                </div>
              </div>
              <div>
                <a
                  href="https://t.me/Nar_Yan"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="icons/telegram.svg"
                    alt=""
                    width={25}
                    height={25}
                  />
                </a>
                <div>
                  Telegram
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
