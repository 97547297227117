import React, { useEffect, useState } from "react";
import { Asteroid } from "./asteroid";
import { Spaceship } from "./spaceship";

const Game: React.FC = () => {
  const [asteroids, setAsteroids] = useState<{ x: number; y: number }[]>([]);
  const spaceship = { x: 150, y: 150 };

  useEffect(() => {
    const interval = setInterval(() => {
      setAsteroids((prev) => prev.map((ast) => ({ ...ast, y: ast.y + 10 })));
      setAsteroids((prev) => prev.filter((ast) => ast.y < window.innerHeight));
      setAsteroids((prev) => [
        ...prev,
        { x: Math.random() * window.innerWidth, y: 0 },
      ]);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
      <svg width="100vw" height="100vh">
        <Spaceship x={spaceship.x} y={spaceship.y} />
        {asteroids.map((ast, idx) => (
          <Asteroid key={idx} x={ast.x} y={ast.y} />
        ))}
      </svg>
    </div>
  );
};

export default Game;
