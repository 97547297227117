import { useState } from "react";
import emailjs from "@emailjs/browser";
import Header from "../../components/header";
import { toast } from "react-toastify";
import Alien from "./alien";
import ButtonLoader from "../../components/buttonLoader";
import "./styles.scss";

const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID || "";
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "";
const publickKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || "";

const Contact = (): JSX.Element => {
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    try {
      setIsLoading(true);
      e.preventDefault();

      const result = await emailjs.send(
        serviceId,
        templateId,
        {
          full_name: fullName,
          from_email: email,
          message: message,
        },
        publickKey
      );

      if (result.status === 200) {
        toast.success("Success!");

        setFullName("");
        setMessage("");
        setEmail("");
      } else {
        toast.error("Something went wrong. Please try again later");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact_container">
      <Header
        background="linear-gradient(59.82deg,#3c3c3c 0%,rgba(243, 238, 238, 0) 49.36%,rgba(0, 220, 231, 0) 50.11%,#1e2224 100%)"
        mobile_menu_bg="linear-gradient(59.82deg, rgb(119 58 42 / 38%) 0%, rgb(119 58 42 / 90%) 49.36%, rgb(119 58 42 / 93%) 50.11%, rgb(30, 34, 36) 100%)"
      />
      <div className="form_content">
        <Alien />
        <div className="page_title">Contact Me</div>
        <div className="description">
          I'd love to hear from you! Whether you have a question about my work,
          want to discuss a potential project, or just want to say hello, feel
          free to get in touch.
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="fullName"
            placeholder="Full Name *"
            required
            onChange={(e) => setFullName(e.target.value)}
            value={fullName}
          />
          <input
            type="email"
            name="email"
            placeholder="Email *"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <textarea
            placeholder="Message *"
            required
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader /> : "Send 🚀"}
          </button>
        </form>
        <p>
          Thank you for visiting my portfolio. I look forward to connecting with
          you!
        </p>
      </div>
    </div>
  );
};

export default Contact;
