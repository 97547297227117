import "./styles.scss";

const Dao = (): JSX.Element => {
  return (
    <div className="dao">
      <img src="images/dao.png" className="dao_image" alt="" />
      <div className="project_text">
        <div className="title">DAO Maker</div>
        <div className="project_description">
          DAO Maker is a pioneering platform aimed at democratizing venture
          capital for the retail sector, making it accessible and safe for
          everyday investors to participate in early-stage funding. It offers a
          variety of structured investment opportunities with different levels
          of risk, designed to cater to the broad spectrum of investor risk
          appetites. The platform supports crowdfunding and tokenized offerings,
          with protections like refundable token offerings to limit investor
          risks while maintaining high potential returns.&nbsp;
          <a href="https://app.daomaker.com/" target="_blank" rel="noreferrer">
            View Project{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Dao;
