export const skils = [
  {
    title: "FrontEnd",
    items: [
      {
        name: "HTML5",
        image: "icons/html.svg",
      },
      {
        name: "CSS3",
        image: "icons/css.svg",
      },
      {
        name: "SASS",
        image: "icons/sass.svg",
      },
      {
        name: "JS",
        image: "icons/javascript.svg",
      },
      {
        name: "React Js",
        image: "icons/react.svg",
      },
      {
        name: "React Native",
        image: "icons/react-native.svg",
      },
      {
        name: "Expo",
        image: "icons/expo.svg",
      },
      {
        name: "TypeScript",
        image: "icons/typescript.svg",
      },
      {
        name: "Next Js",
        image: "icons/next-js.svg",
      },
      {
        name: "MUI",
        image: "icons/material_ui.svg",
      },
      {
        name: "Selenium",
        image: "icons/selenium.svg",
      },
    ],
  },
  {
    title: "BeckEnd",
    items: [
      { name: "Node Js", image: "icons/nodejs.svg" },
      { name: "Express Js", image: "icons/express-js.svg" },
    ],
  },
  {
    title: "Databases",
    items: [
      {
        name: "MongoDB",
        image: "icons/mongodb.svg",
      },
      {
        name: "PostgreSQL",
        image: "icons/postgresql.svg",
      },
      {
        name: "Prisma",
        image: "icons/prisma.svg",
      },
    ],
  },
  {
    title: "Tools",
    items: [
      {
        name: "GitHub",
        image: "icons/github.svg",
      },
      {
        name: "GitLab",
        image: "icons/gitlab.svg",
      },
      {
        name: "Docker",
        image: "icons/docker.svg",
      },
      {
        name: "Postman",
        image: "icons/postman.svg",
      },
      {
        name: "Figma",
        image: "icons/figma.svg",
      },
    ],
  },
];

export const pages = [
  {
    key: 1,
    name: "About Me",
    link: "/about",
    image: "icons/menu/about_me.svg",
  },
  {
    key: 2,
    name: "My Skills",
    link: "/skills",
    image: "icons/menu/skills.svg",
  },
  {
    key: 3,
    name: "My Projects",
    link: "/projects",
    image: "icons/menu/projects.svg",
  },
  {
    key: 4,
    name: "Contact With Me",
    link: "/contact",
    image: "icons/menu/contact.svg",
  },
  // {
  //   key: 5,
  //   name: "Play With Me",
  //   link: "",
  //   image: "icons/menu/game.svg",
  // },
  // {
  //   key: 6,
  //   name: "Coming Soon!!",
  //   link: "",
  //   image: "icons/menu/",
  // },
  // {
  //   key: 7,
  //   name: "Coming Soon!!",
  //   link: "",
  //   image: "icons/menu/",
  // },
  // {
  //   key: 8,
  //   name: "Coming Soon!!",
  //   link: "",
  //   image: "icons/menu/",
  // },
];
