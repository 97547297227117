export interface PlanetPositions {
  mercury: number[];
  venus: number[];
  earth: number[];
  mars: number[];
  jupiter: number[];
  saturn: number[];
  uranus: number[];
  neptune: number[];
  lastUpdate: Date;
}

export const planets: {
  name: keyof PlanetPositions;
  scale: number;
  scane: string;
  rotation: number;
  link: string;
}[] = [
  {
    name: "mercury",
    scale: 0.0002,
    scane: "planets/mercury.glb",
    rotation: 708,
    link: "/skills",
  },
  {
    name: "venus",
    scale: 0.00047,
    scane: "planets/venus.glb",
    rotation: 243,
    link: "/projects",
  },
  {
    name: "earth",
    scale: 0.0005,
    scane: "planets/earth.glb",
    rotation: 20,
    link: "/about",
  },
  {
    name: "mars",
    scale: 0.00026,
    scane: "planets/mars.glb",
    rotation: 5,
    link: "/contact",
  },
  {
    name: "jupiter",
    scale: 0.0056,
    scane: "planets/jupiter.glb",
    rotation: 8,
    link: "",
  },
  {
    name: "saturn",
    scale: 0.0047,
    scane: "planets/saturn.glb",
    rotation: 5,
    link: "",
  },
  {
    name: "uranus",
    scale: 0.002,
    scane: "planets/uranus.glb",
    rotation: 10,
    link: "",
  },
  {
    name: "neptune",
    scale: 0.0019,
    scane: "planets/neptune.glb",
    rotation: 67,
    link: "",
  },
];
