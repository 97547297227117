import Header from "../../components/header";
import { skils } from "../../utils/constants";
import "./styles.scss";

const Skills = (): JSX.Element => {
  return (
    <div className="skills_container">
      <Header
        background="linear-gradient(59.82deg, rgb(60, 60, 60) 0%, rgb(0 0 0 / 52%) 49.36%, rgba(32, 33, 33, 0.44) 50.11%, rgb(30, 34, 36) 100%)"
        mobile_menu_bg="linear-gradient(53deg, #3c3c3c 0%, rgb(116 116 116 / 58%) 49.36%, rgb(116 116 116 / 61%) 50.11%, #1e2224 100%)"
      />
      <div className="content">
        <div className="description">
          Greeting at the heart of my trade. Find out all the instruments,
          technologies, and methods that i have been using for years to produce
          outstanding outcomes.Each expertise i own has been refined through
          actual practice and constant professional growth. Besides, discover
          below how my competencies match with what you need and what we can do
          working together to make your dream true.
        </div>
        <div className="skils_container">
          {skils.map((e) => (
            <div className="skils_fragment" key={e.title}>
              <div className="title">{e.title}</div>
              <div className="skils_items">
                {e.items.map((i) => (
                  <div className="skill" key={i.name}>
                    <div className="hexagon-shape" />
                    <div className="skill_box">
                      <img src={i.image} alt={i.name} className="skill_image" />
                      <div className="name">{i.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
