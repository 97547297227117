import Header from "../../components/header";
import Gains from "../../components/projects/gains";
import Yeti from "../../components/projects/yeti";
import Dao from "../../components/projects/dao";
import PassXYZ from "../../components/projects/passxyz";
import "./styles.scss";

const Projects = (): JSX.Element => {
  return (
    <div className="projects_container">
      <Header
        background="linear-gradient(59.82deg,#3c3c3c 0%,rgba(243, 238, 238, 0) 49.36%,rgba(0, 220, 231, 0) 50.11%,#1e2224 100%)"
        mobile_menu_bg="linear-gradient(53deg, #3c3c3c 0%, rgb(116 116 116 / 58%) 49.36%, rgb(116 116 116 / 61%) 50.11%, #1e2224 100%)"
      />
      <img src="gifs/alient.gif" alt="alient" className="alient" />
      <div className="content">
        <div className="description">
          Welcome to my projects! Here, you'll find a showcase of the various
          projects I've worked on. Each project represents a unique challenge
          and an opportunity for growth, allowing me to hone my skills as a
          developer.
        </div>
        <div className="projects_content">
          <Gains />
          <Yeti />
          <PassXYZ />
          <Dao />
        </div>
      </div>
    </div>
  );
};

export default Projects;
