import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pages } from "../../utils/constants";
import "./styles.scss";

const Menu = (): JSX.Element => {
  const [activeItem, setActiveItem] = useState<number>();
  const navigate = useNavigate();


  const onItemClick = (item: number, path: string): void => {
    setActiveItem(item);
    navigate(path)
  };

  return (
    <div className="menu">
      <p className="title">Let's Explore Space Together</p>
      <div className="items">
        {pages.map((e) => (
          <div>
            <p
              key={e.key}
              className={e.key === activeItem ? "activeItem" : ""}
              onClick={() => onItemClick(e.key, e.link)}
            >
              {e.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
