import React from 'react';

interface Props {
    x: number;
    y: number;
}

export const Spaceship: React.FC<Props> = ({ x, y }) => {
    return (
        <polygon points={`${x},${y} ${x-20},${y+60} ${x+20},${y+60}`} fill="lime" />
    );
};