import React from "react";

interface Props {
  x: number;
  y: number;
}

export const Asteroid: React.FC<Props> = ({ x, y }) => {
  return <circle cx={x} cy={y} r="20" fill="grey" />;
};
